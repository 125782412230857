.main-asset {
	max-width: 650px;
	width: 100%;
	margin-bottom: 140px;
	padding-left: 7.5px;
	padding-right: 7.5px;
}

.search-popup-asset {
	width: 100%;
	margin: 10px !important;
	height: 220px;
	min-height: 160px;
}

.asset-card {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.top-row-asset {
	display: grid;
	grid-template-columns: 1fr;
	grid-column-gap: 7.5px;
	padding: 7.5px;
}

.top-row-asset {
	display: grid;
	grid-template-columns: 50% 25% 25%;
	grid-column-gap: 7.5px;
	padding: 7.5px;
}

.top-content-asset {
	font-size: 18px;
	color: #000000;
	margin-bottom: 5px;
}

.bottom-row-asset {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	padding: 7.5px;
}

.label-asset {
	font-size: 16px;
	color: var(--dark-blue);
	font-weight: 500;
}

.header-container-asset {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.content-asset {
	font-size: 16px;
	margin-bottom: 5px;
	margin-top: auto;
}

#assetpagdiv {
	margin-top: 20px;
}

.top-row-choose-asset {
	display: grid;
	grid-template-columns: 50% 30% 20%;
	grid-column-gap: 5.5px;
	padding: 5.5px;
}

.MuiIconButton-root {
	padding:0 !important;
}

.MuiPaper-root {
	overflow-y: visible !important;
}

.MuiCardHeader-title{
	font-size: 20px !important;
}
