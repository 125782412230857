.clear-button-container {
	flex: 0;
	margin-top: 28px;
	justify-self: flex-end;
}

.clear-button {
	cursor: pointer;
}

.dialog-action-buttons {
	display: flex;
	justify-content: space-between !important;
	flex-direction: row;
}

.select-container {
	display: flex;
	column-gap: 10px;
	width: 100%;
	margin: 10px 20px;
}

.select-sub-container {
	flex: 1;
}
