#details-outer,
#action-buttons {
	padding: 5px;
	max-width: 95%;
	margin: 5px auto;
	min-width: 280px;
}

#document-upload-button {
	background-color: var(--light-blue);
	margin-bottom: 15px;
	width: 100%;
	color: white;
}

#submit-expense-button {
	background-color: #5cb85c;
	margin-bottom: 15px;
	width: 100%;
	color: white;
}

#edit-expense-button {
	background-color: #ffc107;
	margin-bottom: 15px;
	width: 100%;
	color: white;
}
