.main-form-library {
	max-width: 650px;
	width: 100%;
	margin-bottom: 140px;
	padding-left: 7.5px;
	padding-right: 7.5px;
}

.no-templates-card {
	max-width: 90%;
	margin: auto;
	text-align: center;
	margin-top: 25px;
	font-size: 22px;
	font-style: italic;
	line-height: 30px;
}

.form-card {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 20px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.top-row-form-card {
	display: grid;
	grid-template-columns: 100%;
	grid-column-gap: 7.5px;
	padding: 7.5px;
}

.bottom-row-form-card {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	padding: 7.5px;
	margin-bottom: 5px;
}

.label-form {
	font-size: 16px;
	color: var(--dark-blue);
	font-weight: 500;
}

.header-container-form {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.content-form {
	font-size: 16px;
	margin-bottom: 5px;
	margin-top: auto;
}

.form-start-edit-button {
	border: 0 !important;
	border-radius: 0 !important;
	height: 35px;
	outline: none;
	/* text-transform: uppercase; */
}

.library-p-tag {
	margin-left: 5px !important;
	font-size: 20px;
	margin: auto;
	display: inline;
	font-size: 500;
}

#create-form-date-input {
	border: 0px !important;
	background-color: transparent !important;
}
