
.download-icon-holder {
    display: flex;
    align-items: center;
}

.close-modal-button {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}

.image-holder {
    object-fit: fill;
    max-width: 400px;
    max-height: 400px;
}

.background-color-grey {
    background-color: var(--light-grey) !important;
}

.background-color-red {
    background-color: var(--light-red) !important;
}

.color-white {
    color: white !important;
}

.margin-top {
    margin-top: 10px;
}

.text-align-center {
    text-align: center;
}

.cursor-pointer {
    cursor: pointer;
}

.modal-border {
    border-top: 1px solid var(--light-grey);
}