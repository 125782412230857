.table-left-padding {
	padding-left: 0px !important;
}

.table-head-cell {
	font-size: 16px;
	font-weight: 1000;
	color: var(--dark-blue);
	font-family: 'Montserrat', sans-serif;
}

.table-body-cell {
	font-size: 18px;
	color: #424242;
}
