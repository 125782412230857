#photo-canvas {
    object-fit: fill;
    /*height: 100%;*/
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
}

#drawing-canvas {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}

#photo-screen-container,
#edit-photo-container {
    /* height: 100vh; */
    /*display: grid;*/
    /*grid-auto-rows: minmax(min-content, max-content);*/
}

video {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.photo-screen-actions {
    background-color: blue;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.white {
    color: white !important;
}

.padding-25-15 {
    padding: 2.5px 15px;
    margin-top: 15px;
}
