#details-outer,
#action-buttons {
	padding: 5px;
	max-width: 95%;
	margin: 5px auto;
	min-width: 280px;
}

.top-card {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 25px;
	padding: 6px;
	margin-bottom: 15px;
}

.settingCard {
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	padding: 20px;

}

.field {
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
}

.field-content {
	font-size: 18px;
	margin-bottom: 7.5px;
	color: #424242;
	max-width: 100%;
}

.field-content-asset {
	font-size: 18px;
	margin-bottom: 7.5px;
	color: #424242;
	max-width: 100%;
}

.field-content2 {
	font-size: 14px;
	margin-bottom: 7.5px;
	font-style: italic;
	color: #424242;
	max-width: 100%;
}

.field-label {
	font-size: 16px;
	font-weight: 500;
	color: var(--dark-blue);
}

.field-label-asset {
	font-size: 16px;
	font-weight: 1000;
	color: var(--dark-blue);
	font-family: 'Montserrat', sans-serif;
}

.field-label-repeatable-count {
	font-size: 16px;
	font-weight: 1000;
	color: var(--dark-blue);
	font-family: 'Montserrat', sans-serif;
	margin-bottom: 5px;
}

.field-label-current-count {
	font-size: 16px;
	font-weight: 1000;
	font-family: 'Montserrat', sans-serif;
	color: crimson;
}

.assetField {
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
}

#details-outer-asset {
	padding: 0px;
}

.assetHeading {
	font-size: 30px !important;
	font-weight: 700;
	font-family: 'Montserrat', sans-serif;
}

.pull-right {
	justify-self: flex-end;
}

.full-line {
	grid-column: 1 / 3;
}

.full-line-asset {
	grid-column: 1 / 3;

}

.action-buttons {
	display: flex;
	flex-direction: column;
	padding: 0;
}

.photos-title {
	margin-bottom: 5px;
	padding-bottom: 5px;
}

.documents-title {
	padding: 10px;
	color: inherit;
	background-color: inherit;
}

hr {
	margin: 0;
}

.photos-container {
	padding: 10px;
}

.padding {
	padding: 12.5px;
	margin-bottom: 15px;
}

.grid-photo {
	object-fit: fill;
	max-width: 100%;
	max-height: 80px;
	border: 1px solid var(--light-grey);
	border-radius: 5px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	min-height: 80px;
}

.photo-modal {
	object-fit: fill;
	max-width: 100%;
	max-height: 100%;
}

#notes-card {
	width: 100%;
}

#documents-card {
	width: 100%;
}

#documents-card {
	width: 100%;
}

TableCell {
	padding: 0;
}

#signature-container {
	margin-top: 15px;
	width: 100%;
	height: 200px;
	border: 1px solid rgba(0, 0, 0, 0.54);
}

#signature-heading {
	color: rgba(0, 0, 0, 0.54);
	font-size: 16px;
	margin-top: 15px;
}

.accept-button {
	background-color: var(--light-green) !important;
	color: #fafafa !important;
	margin-bottom: 15px !important;
	width: 100%;
}

.start-travel-button {
	background-color: var(--light-blue) !important;
	color: #fafafa !important;
	margin-bottom: 15px !important;
	width: 100%;
}

.risk-assessment-button {
	background-color: var(--dark-red) !important;
	color: #fafafa !important;
	margin-bottom: 15px !important;
	width: 100%;
}

.arrive-button {
	background-color: #ffa726 !important;
	color: #fafafa !important;
	margin-bottom: 15px !important;
	width: 100%;
}

.leave-site-button {
	background-color: #b71c1c !important;
	color: #fafafa !important;
	margin-bottom: 15px !important;
	width: 100%;
}

.button-disabled {
	background-color: rgba(0, 0, 0, 0.12);
	color: rgba(0, 0, 0, 0.26);
	width: 100%;
}

.disabled-button-note {
	color: var(--hot-pink);
	text-align: center;
	margin-bottom: 15px;
	font-style: italic;
	font-size: 14px;
	margin-top: 5px;
}

#attach-document-button {
	background-color: white;
	color: darkgray;
}

.pink-row {
	background-color: var(--light-pink);
}

.hot-pink {
	color: var(--hot-pink);
}

#attach-form-button {
	background-color: white;
	color: darkgray;
}

#raise-jobs-button {
	background-color: white;
	color: darkgray;
}


#document-upload-button {
	background-color: var(--light-blue);
	margin-bottom: 15px;
	width: 100%;
	color: white;
}

#stock-upload-button {
	background-color: var(--light-amber);
	margin-bottom: 15px;
	width: 100%;
	color: white;
}

#offline-documents-container {
	display: flex;
	justify-content: center;
}

.outer-card {
	display: flex;
	justify-content: center;
	color: var(--hot-pink);
	font-size: 17px;
}

.stock-list {
	width: 100% !important;
}

.note-cell-1-header {
	word-break: break-all;
	width: 25%;
	padding: 6.5px !important;
	font-size: 12px !important;
	color: rgba(0, 0, 0, 0.54) !important;
}

.note-cell-2-header {
	word-break: break-all;
	width: 50%;
	padding: 6.5px !important;
	font-size: 12px !important;
	color: rgba(0, 0, 0, 0.54) !important;
}

.note-cell-3-header {
	word-break: break-all;
	width: 25%;
	padding: 6.5px !important;
	font-size: 12px !important;
	color: rgba(0, 0, 0, 0.54) !important;
}

.note-cell-1 {
	word-break: break-all;
	width: 25%;
	padding: 6.5px !important;
	font-size: 11px !important;
}

.note-cell-2 {
	word-break: break-all;
	word-break: break-word;
	width: 50%;
	padding: 6.5px !important;
	font-size: 12px !important;
}

.note-cell-3 {
	word-break: break-all;
	width: 25%;
	padding: 6.5px !important;
	font-size: 11px !important;
}

.photo-card {
	display: flex;
	justify-content: center;
	color: var(--hot-pink);
	font-size: 20px;
	height: 45px;
	margin-bottom: 15px !important;
}

.main .main {
	margin-bottom: 0px !important;
}

.form-cell-name-header {
	width: 40%;
	color: rgba(0, 0, 0, 0.54) !important;
	font-weight: 500;
	padding: 6.5px !important;
	font-size: 12px !important;
}

.form-cell-task-header {
	width: 25%;
	color: rgba(0, 0, 0, 0.54) !important;
	font-weight: 500;
	padding: 6.5px !important;
	font-size: 12px !important;
}

.form-cell-button-header {
	width: 10%;
	color: rgba(0, 0, 0, 0.54) !important;
	font-weight: 500;
	padding: 6.5px !important;
	font-size: 12px !important;
}

.form-cell-name {
	width: 40%;
	color: black !important;
	font-weight: 500;
	padding: 6.5px !important;
	font-size: 11px !important;
}

.form-cell-task {
	width: 25%;
	color: black !important;
	font-weight: 500;
	padding: 6.5px !important;
	font-size: 11px !important;
}

.form-cell-button {
	width: 10%;
	padding: 6.5px !important;
}

.form-Template-cell {
	padding: 6.5px !important;
}