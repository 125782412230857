.storage-area-details-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.storage-area-card {
	border: 2.5px solid var(--light-grey);
	margin-bottom: 10px;
	padding: 10px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	display: flex;
	flex-direction: column;
	width: 100%;
}

.storage-area-details {
	width: 100%;
	padding-top: 20px;
	padding-left: 24px;
	padding-right: 24px;
}

.label-storage-area {
	font-size: 16px;
	color: rgb(238, 8, 8);
	font-weight: 500;
}
.content-storage-area {
	font-size: 14px;
	margin-bottom: 5px;
	margin-top: auto;
}

.storage-area-details .storage-area-card {
	padding: 6px;
	margin-bottom: 15px;
	border: 1px solid grey;
}

.storage-area-details .label-storage-area {
	font-size: 16px;
	font-weight: 1000;
	color: var(--dark-blue);
	font-family: 'Montserrat', sans-serif;
}

.storage-area-details .content-storage-area {
	font-size: 18px;
	margin-bottom: 7.5px;
	color: #424242;
	max-width: 100%;
}

.storage-area-details .first {
	color: black;
	font-size: 30px !important;
	font-weight: 700;
	font-family: 'Montserrat', sans-serif;
}

.no-stocks-text {
	font-size: 20px;
	font-style: italic;
	line-height: 30px;
	color: var(--hot-pink);
}
