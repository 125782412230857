.main-site {
	max-width: 650px;
	width: 100%;
	margin-bottom: 140px;
	padding-left: 7.5px;
	padding-right: 7.5px;
}

.search-popup-site {
	width: 100%;
	margin: 10px !important;
	height: 160px;
	min-height: 160px;
}

.card-shadow-site {
	box-shadow: 0 3px 6px rgba(34, 109, 248, 0.288), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.site-card {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.top-row-site {
	display: grid;
	grid-template-columns: 1fr;
	grid-column-gap: 7.5px;
	padding: 7.5px;
}

.top-row-choose-site {
	display: grid;
	grid-template-columns: 60% 40%;
	grid-column-gap: 7.5px;
	padding: 7.5px;
}

.top-row-choose-site2 {
	display: grid;
	grid-template-columns: 50% 40% 10%;
	grid-column-gap: 7.5px;
	padding: 7.5px;
}

.top-content-site {
	font-size: 14px;
	color: #000000;
	margin-bottom: 5px;
}

.bottom-row-site {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	padding: 7.5px;
}

.label-site {
	font-size: 16px;
	color: rgb(238, 8, 8);
	font-weight: 500;
}

.header-container-site {
	display: flex;
	flex-direction: column;
	/*justify-content: space-between;*/
}

.content-site {
	font-size: 14px;
	margin-bottom: 5px;
	margin-top: auto;
}

#sitepagdiv {
	margin-top: 20px;
}

.MuiIconButton-root {
	padding: 0 !important;
}
