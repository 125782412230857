.card-shadow-alt {
	box-shadow: 0 3px 6px #dedede, 0 3px 6px #dedede;
	border: solid;
	border-color: #d0d0d0;
	border-width: 1px;
	border-radius: 20px;
	padding: 22px;
}

.field {
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
}

.field-label-alt {
	color: var(--dark-blue);
	font-family: 'Roboto';
}

.field-content-alt {
	margin-bottom: 7.5px;
	color: #424242;
	max-width: 100%;
	font-family: 'Roboto';
}

.field-content-link {
	margin-bottom: 7.5px;
	color: #424242;
	max-width: 100%;
}

.full-line {
	grid-column: 1 / 3;
}

.top-card-alt {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 25px;
	margin-bottom: 15px;
}

.main-card-left {
	border-left: solid;
    border-left-width: 0.1px;
    border-left-style: ridge;
    padding-left: inherit;
}

.action-buttons-alt {
	display: flex;
	flex-direction: column;
	padding: 0;
}

.permit-to-work-button {
	background-color: #D5F4D4 !important;
    color: #00B297 !important;
	margin-bottom: 15px !important;
	width: 100%;
	box-shadow: none !important;
	border-radius: 20px !important;
}


.accept-button {
	background-color: #E5E7FF !important;
    color: #373F91 !important;
	margin-bottom: 15px !important;
	width: 100%;
	box-shadow: none !important;
	border-radius: 20px !important;
}

.start-travel-button {
	background-color: #C7DFFF !important;
    color: #3269B1 !important;
	margin-bottom: 15px !important;
	width: 100%;
	box-shadow: none !important;
	border-radius: 20px !important;
}

.risk-assessment-button {
	background-color: #AEDED2 !important;
    color: #19A580 !important;
	margin-bottom: 15px !important;
	width: 100%;
	box-shadow: none !important;
	border-radius: 20px !important;
}

.arrive-button {
	background-color: #D0EEFF !important;
    color: #00456B !important;
	margin-bottom: 15px !important;
	width: 100%;
	box-shadow: none !important;
	border-radius: 20px !important;
}

.leave-site-button {
	background-color: #FFE3C4 !important;
	color: #EAA04D !important;
	margin-bottom: 15px !important;
	width: 100%;
	box-shadow: none !important;
	border-radius: 20px !important;
}

.alt-card-title {
	padding: 10px;
	color: inherit;
	background-color: inherit;
	display: flex;
	flex-direction: column;
}

.card-header-icon-n-title{
	font-weight: 500;
	display: flex;
	align-items: center;
	padding-right: 0 !important;
}

.border-radius {
	border-radius: 20px;
	padding: 16px !important;
}

.padding {
	padding: 12.5px;
	margin-bottom: 15px;
}

.outer-card {
	display: flex;
	justify-content: center;
	color: var(--hot-pink);
	font-size: 17px;
}


.grid-photo {
	object-fit: fill;
	max-width: 100%;
	max-height: 80px;
	border: 1px solid var(--light-grey);
	border-radius: 5px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	min-height: 80px;
}

.photo-modal {
	object-fit: fill;
	max-width: 100%;
	max-height: 100%;
}

.photos-container {
	padding: 10px;
}

.note-cell-1 {
	word-break: break-all;
	width: 25%;
	padding: 6.5px !important;
	font-size: 11px !important;
}

.note-cell-2 {
	word-break: break-all;
	word-break: break-word;
	width: 50%;
	padding: 6.5px !important;
	font-size: 12px !important;
}

.note-cell-3 {
	word-break: break-all;
	width: 25%;
	padding: 6.5px !important;
	font-size: 11px !important;
}

.pink-row {
	background-color: var(--light-pink);
}

.no-elements-card-text {
	display: flex;
	justify-content: center;
	color: #738678;
    font-size: 15px;
}

.loading-elements-card-container {
	text-align: center;
}

.sub-title-in-card {
	margin-top: 20px;
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: 500;
}

.qr-code-button {
	background-color: #E5E7FF !important;
	color: #373F91 !important;
	margin-bottom: 15px !important;
	border-radius: 20px !important;
	box-shadow: none !important;
}

.photo-icon-holder {
	font-size: 20px;
	margin-right: 5px;
}

.qr-code-modal-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.information-card-title {
	font-weight: 500;
	font-size: 22px;
	margin-bottom: 8px;
}

.icon-button-holder {
	margin-left: 4px;
	padding: 0;
	vertical-align: text-bottom;
}

.pin-icon-holder {
	color: #e91e63;
	font-size: 22px;
}

.almost-bold-text {
	font-weight: 500;
}

.break-word-wrap {
	word-wrap: break-word;
}

.touch-app-icon-color {
	color: #A252D4;
}