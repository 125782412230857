#outer-card {
	padding: 10px;
	padding-top: 5px;
	max-width: 95%;
	margin: 12.5px auto;
	min-width: 280px;
}

#outer-card-button {
	padding: 10px;
	max-width: 95%;
	margin: auto 12.5px;
	min-width: 280px;
}

#loadMoreBtn {
	margin-top: 10px;
	border-radius: 20px;    
	color: #3269B1 !important;
	border-color: #3269B1 !important;
}

.card-shadow {
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

p {
	margin: 0;
	font-size: 20px;
}

.top-row {
	display: flex;
	margin-bottom: 5px;
	justify-content: space-between;
}

.status-container {
	display: flex;
}

.task-number {
	padding: 5.5px 7px;
	border-radius: 5px;
	font-size: 14.5px;
	font-weight: 500;
}

.due-today-flag {
	padding: 5.5px 7px;
	border-radius: 5px;
	font-size: 14.5px;
	background-color: var(--light-red);
	color: white;
	margin-right: 5px;
	font-weight: 550;
}

.status-container {
	display: flex;
	flex-direction: row;
}

.header {
	font-size: 16px;
	font-weight: 500;
}

.content {
	font-size: 14px;
	/* font-style: italic; */
	margin-left: 5px;
	line-height: 15px;
}

.content-container {
	display: grid;
	margin-bottom: 10px;
	grid-template-columns: 30% 70%;
}

.cell1 {
	word-break: break-all;
	width: 25%;
	padding: 6.5px !important;
	font-size: 11px !important;
}

.cell2 {
	word-break: break-all;
	width: 50%;
	padding: 6.5px !important;
}

.cell3 {
	word-break: break-all;
	width: 40%;
	padding: 6.5px !important;
	font-size: 12px !important;
}

.cell4 {
	word-break: break-all;
	width: 20%;
	padding: 6.5px !important;
	font-size: 12px !important;
}

.cell5 {
	word-break: break-all;
	width: 10%;
	padding: 6.5px !important;
	font-size: 12px !important;
}

.cell {
	word-break: break-all;
	/* padding: 6.5px !important;
	font-size: 12px !important; */
}

.row-cell {
	display: flex !important;
}