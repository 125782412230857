.timesheet-cell {
    padding-left: 0px !important;
    padding-right: 7.5px !important;
}

td:last-child,
th:last-child {
    padding-right: 7.5px !important;
}

.time-string {
    font-size: 12px;
    font-weight: bold;
}

.date-string {
    font-size: 10px;
    font-style: italic;
}

/* .task-number {
    font-size: 12px;
} */

.time-sheet-details tbody tr:nth-child(odd) {
    background-color: #f2f2f2;
}

.header-text {
    color: var(--dark-blue);
}

.edited-row {
    background-color: var(--light-pink) !important;
}
