.alertBar {
    background-color: #eceef2;
    padding: 10px;
    line-height: 1.5;
    border-radius: 10px;
}

.alertIcon {
    padding-top: 5px;
}

.field-holder-name {
    overflow-wrap: break-word;
    margin-bottom: 5px;
    font-size: 20px !important;
	font-weight: 500;
	font-family: Roboto;
}

.padding-left {
    padding-left: 5px;
}

.add-qr-code-button {
    background-color: none !important;
    color: #3269B1 !important;
	width: 100%;
	box-shadow: none !important;
	border-radius: 20px !important;
    /*border-color: #C7DFFF !important;*/
    border-color: #3269B1 !important;
}

.view-locations-button {
    background-color: #D0EEFF !important;
	color: #00456B !important;
	width: 100%;
	box-shadow: none !important;
	border-radius: 20px !important;
}

.view-sublocations-button {
	background-color: #D0EEFF !important;
	color: #00456B !important;
	width: 100%;
	box-shadow: none !important;
	border-radius: 20px !important;
}

.raise-job-task-button {
    background-color: none !important;
	color: #EAA04D !important;
	width: 100%;
	box-shadow: none !important;
	border-radius: 20px !important;
    border-color: #EAA04D !important;
	line-height: 1 !important;
}

.create-asset-button {
    background-color: none !important;
    color: #373F91 !important;
	width: 100%;
	box-shadow: none !important;
	border-radius: 20px !important;
    border-color: #373F91 !important;
	line-height: 1 !important;
}

.error-msg {
	font-size: 12px;
	color: #ff0000;
	padding: 5px;
}


@media only screen and (min-width: 430px) {
	.top-card-alt-with-two-buttons {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 25px;
		margin-bottom: 15px;
	}

	.view-job-tasks-button {
		background-color: #FFE3C4 !important;
		color: #EAA04D !important;
		width: 100%;
		box-shadow: none !important;
		border-radius: 20px !important;
		line-height: 1 !important;
	}

	.view-assets-button {
		background-color: #E5E7FF !important;
		color: #373F91 !important;
		width: 100%;
		box-shadow: none !important;
		border-radius: 20px !important;
		line-height: 1 !important;
	}
	
  }

@media only screen and (max-width: 430px) {
	.top-card-alt-with-two-buttons {
		display: grid;
		grid-column-gap: 25px;
		margin-bottom: 15px;
	}

	.view-job-tasks-button {
		background-color: #FFE3C4 !important;
		color: #EAA04D !important;
		width: 100%;
		box-shadow: none !important;
		border-radius: 20px !important;
		line-height: 1 !important;
		margin-bottom: 12.5px !important;
	}

	.view-assets-button {
		background-color: #E5E7FF !important;
		color: #373F91 !important;
		width: 100%;
		box-shadow: none !important;
		border-radius: 20px !important;
		line-height: 1 !important;
		margin-bottom: 12.5px !important;
	}
	
  }
