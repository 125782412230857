.inputContainer {
    display: flex;
    flex-direction: column;
    width: 350px;
    padding: 25px;
}
.text-inputs {
    padding: 10px;
    margin-bottom: 10px;
}

.login-buttons {
    margin-top: 35px !important;
}

.outer-container {
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

#logo {
    align-self: center;
}

body {
    overscroll-behavior: contain;
}

.title-text {
    font-size: 24px;
    margin: auto;
    padding-top: 5px;
    color: #526370;
}

#loginBtn {
    background-color: #526370;
    color: white;
}
