.details-list {
    position: absolute !important;
    bottom: 0;
    padding-top: 0 !important;
    padding-bottom: 0.3em !important;
    width: 100%;
}

.details-list .version-information {
    padding-bottom: 0.2em;
}

.details-list .version-information > .MuiListItemText-root {
    margin-bottom: 0;
}

.details-list .version-information > .MuiListItemText-root > .MuiListItemText-primary {
    font-size: 0.6rem;
}

.details-list .check-for-updates {
    right: 0.8em;
}

.details-list .user-information > .MuiListItemText-root > .MuiListItemText-primary {
    font-size: 0.8rem;
}

.drawer-div-container {
    margin-bottom: 60px;
}

.appbar {
    box-shadow: none !important;
    background-color: #373f91 !important;
}

.appbar-title {
    margin-left: 15px;
    flex-grow: 1;
    font-weight: 500;
    font-family: Roboto;
}

.menu-icon {
    font-size: 30px !important; 
    font-weight: lighter !important;
}
.alertBar {
    background-color: #eceef2;
    padding: 10px;
    line-height: 1.5;
    border-radius: 10px;
}

.alertIcon {
    padding-top: 5px;
}

.field-holder-name {
    overflow-wrap: break-word;
    margin-bottom: 5px;
    font-size: 20px !important;
	font-weight: 500;
	font-family: Roboto;
}

.padding-left {
    padding-left: 5px;
}

.add-qr-code-button {
    background-color: none !important;
    color: #3269B1 !important;
	width: 100%;
	box-shadow: none !important;
	border-radius: 20px !important;
    /*border-color: #C7DFFF !important;*/
    border-color: #3269B1 !important;
}

.view-locations-button {
    background-color: #D0EEFF !important;
	color: #00456B !important;
	width: 100%;
	box-shadow: none !important;
	border-radius: 20px !important;
}

.view-sublocations-button {
	background-color: #D0EEFF !important;
	color: #00456B !important;
	width: 100%;
	box-shadow: none !important;
	border-radius: 20px !important;
}

.raise-job-task-button {
    background-color: none !important;
	color: #EAA04D !important;
	width: 100%;
	box-shadow: none !important;
	border-radius: 20px !important;
    border-color: #EAA04D !important;
	line-height: 1 !important;
}

.create-asset-button {
    background-color: none !important;
    color: #373F91 !important;
	width: 100%;
	box-shadow: none !important;
	border-radius: 20px !important;
    border-color: #373F91 !important;
	line-height: 1 !important;
}

.error-msg {
	font-size: 12px;
	color: #ff0000;
	padding: 5px;
}


@media only screen and (min-width: 430px) {
	.top-card-alt-with-two-buttons {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 25px;
		margin-bottom: 15px;
	}

	.view-job-tasks-button {
		background-color: #FFE3C4 !important;
		color: #EAA04D !important;
		width: 100%;
		box-shadow: none !important;
		border-radius: 20px !important;
		line-height: 1 !important;
	}

	.view-assets-button {
		background-color: #E5E7FF !important;
		color: #373F91 !important;
		width: 100%;
		box-shadow: none !important;
		border-radius: 20px !important;
		line-height: 1 !important;
	}
	
  }

@media only screen and (max-width: 430px) {
	.top-card-alt-with-two-buttons {
		display: grid;
		grid-column-gap: 25px;
		margin-bottom: 15px;
	}

	.view-job-tasks-button {
		background-color: #FFE3C4 !important;
		color: #EAA04D !important;
		width: 100%;
		box-shadow: none !important;
		border-radius: 20px !important;
		line-height: 1 !important;
		margin-bottom: 12.5px !important;
	}

	.view-assets-button {
		background-color: #E5E7FF !important;
		color: #373F91 !important;
		width: 100%;
		box-shadow: none !important;
		border-radius: 20px !important;
		line-height: 1 !important;
		margin-bottom: 12.5px !important;
	}
	
  }

.cursor-pointer {
    cursor: pointer;
}

.label-margin {
    margin-top: 2px !important;
}

.icon {
    font-size: 20px !important;
}

.action-button {
    background-color: #E5E7FF !important;
    color: #373F91 !important;
    margin-bottom: 15px !important;
    border-radius: 20px !important;
    box-shadow: none !important;
    width: 100%;
    margin-top: 10px !important;
}

.expansion-icon-holder {
    display: flex;
    align-items: center;
    padding-left: 0 !important;
}

.card-title-max-width {
    max-width: 150px;
}
#photo-canvas {
    object-fit: fill;
    /*height: 100%;*/
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
}

#drawing-canvas {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}

#photo-screen-container,
#edit-photo-container {
    /* height: 100vh; */
    /*display: grid;*/
    /*grid-auto-rows: minmax(min-content, max-content);*/
}

video {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.photo-screen-actions {
    background-color: blue;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.white {
    color: white !important;
}

.padding-25-15 {
    padding: 2.5px 15px;
    margin-top: 15px;
}


.download-icon-holder {
    display: flex;
    align-items: center;
}

.close-modal-button {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}

.image-holder {
    object-fit: fill;
    max-width: 400px;
    max-height: 400px;
}

.background-color-grey {
    background-color: var(--light-grey) !important;
}

.background-color-red {
    background-color: var(--light-red) !important;
}

.color-white {
    color: white !important;
}

.margin-top {
    margin-top: 10px;
}

.text-align-center {
    text-align: center;
}

.cursor-pointer {
    cursor: pointer;
}

.modal-border {
    border-top: 1px solid var(--light-grey);
}
.document-tag-list {
  display: flex !important;
  flex-wrap: wrap;
}

.document-tag {
  margin: 5px;
  margin-right: 0;
	font-weight: 550;
}

.document-tag-placeholder.MuiChip-outlined {
	border-style: dashed !important;
	color: lightgray !important;
}

.document-tag-hover:hover {
	cursor: pointer;
}

.document-tag-chip-disabled {
	cursor: not-allowed !important;
}

.color-black {
	color: black;
}

.left-margin {
	margin-left: 12px;
}

.icon-holder {
	position: absolute;
	cursor: pointer;
	top: 5px;
	right: 5px;
}

.no-display {
	display: none;
}
.select-asset-button {
    background-color: #E5E7FF !important;
    color: #373F91 !important;
    margin-bottom: 25px !important;
    width: 100% !important;
    box-shadow: none !important;
    border-radius: 20px !important;
}

.old-select-asset-button {
    background-color: #b821f3 !important;
	color: white !important;
	width: 100% !important;
	margin-right: 5px !important;
	margin-bottom: 15px !important;
}

.scan-asset-button {
    background-color: #ffe2c0 !important;
    color: #f69c35 !important;
    margin-bottom: 25px !important;
    width: 100% !important;
    box-shadow: none !important;
    border-radius: 20px !important;
}

.old-scan-asset-button {
    background-color: #f59e38 !important;
	color: white !important;
	width: 100% !important;
	margin-left: 5px !important;
	margin-bottom: 15px !important;
}

.buttons-grid {
    text-align: center;
    display: flex;
}

.label-margin {
    margin-top: 2px !important;
}

.icon {
    font-size: 18px !important;
}
#details-outer,
#action-buttons {
	padding: 5px;
	max-width: 95%;
	margin: 5px auto;
	min-width: 280px;
}

.top-card {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 25px;
	padding: 6px;
	margin-bottom: 15px;
}

.settingCard {
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	padding: 20px;

}

.field {
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
}

.field-content {
	font-size: 18px;
	margin-bottom: 7.5px;
	color: #424242;
	max-width: 100%;
}

.field-content-asset {
	font-size: 18px;
	margin-bottom: 7.5px;
	color: #424242;
	max-width: 100%;
}

.field-content2 {
	font-size: 14px;
	margin-bottom: 7.5px;
	font-style: italic;
	color: #424242;
	max-width: 100%;
}

.field-label {
	font-size: 16px;
	font-weight: 500;
	color: var(--dark-blue);
}

.field-label-asset {
	font-size: 16px;
	font-weight: 1000;
	color: var(--dark-blue);
	font-family: 'Montserrat', sans-serif;
}

.field-label-repeatable-count {
	font-size: 16px;
	font-weight: 1000;
	color: var(--dark-blue);
	font-family: 'Montserrat', sans-serif;
	margin-bottom: 5px;
}

.field-label-current-count {
	font-size: 16px;
	font-weight: 1000;
	font-family: 'Montserrat', sans-serif;
	color: crimson;
}

.assetField {
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
}

#details-outer-asset {
	padding: 0px;
}

.assetHeading {
	font-size: 30px !important;
	font-weight: 700;
	font-family: 'Montserrat', sans-serif;
}

.pull-right {
	justify-self: flex-end;
}

.full-line {
	grid-column: 1 / 3;
}

.full-line-asset {
	grid-column: 1 / 3;

}

.action-buttons {
	display: flex;
	flex-direction: column;
	padding: 0;
}

.photos-title {
	margin-bottom: 5px;
	padding-bottom: 5px;
}

.documents-title {
	padding: 10px;
	color: inherit;
	background-color: inherit;
}

hr {
	margin: 0;
}

.photos-container {
	padding: 10px;
}

.padding {
	padding: 12.5px;
	margin-bottom: 15px;
}

.grid-photo {
	object-fit: fill;
	max-width: 100%;
	max-height: 80px;
	border: 1px solid var(--light-grey);
	border-radius: 5px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	min-height: 80px;
}

.photo-modal {
	object-fit: fill;
	max-width: 100%;
	max-height: 100%;
}

#notes-card {
	width: 100%;
}

#documents-card {
	width: 100%;
}

#documents-card {
	width: 100%;
}

TableCell {
	padding: 0;
}

#signature-container {
	margin-top: 15px;
	width: 100%;
	height: 200px;
	border: 1px solid rgba(0, 0, 0, 0.54);
}

#signature-heading {
	color: rgba(0, 0, 0, 0.54);
	font-size: 16px;
	margin-top: 15px;
}

.accept-button {
	background-color: var(--light-green) !important;
	color: #fafafa !important;
	margin-bottom: 15px !important;
	width: 100%;
}

.start-travel-button {
	background-color: var(--light-blue) !important;
	color: #fafafa !important;
	margin-bottom: 15px !important;
	width: 100%;
}

.risk-assessment-button {
	background-color: var(--dark-red) !important;
	color: #fafafa !important;
	margin-bottom: 15px !important;
	width: 100%;
}

.arrive-button {
	background-color: #ffa726 !important;
	color: #fafafa !important;
	margin-bottom: 15px !important;
	width: 100%;
}

.leave-site-button {
	background-color: #b71c1c !important;
	color: #fafafa !important;
	margin-bottom: 15px !important;
	width: 100%;
}

.button-disabled {
	background-color: rgba(0, 0, 0, 0.12);
	color: rgba(0, 0, 0, 0.26);
	width: 100%;
}

.disabled-button-note {
	color: var(--hot-pink);
	text-align: center;
	margin-bottom: 15px;
	font-style: italic;
	font-size: 14px;
	margin-top: 5px;
}

#attach-document-button {
	background-color: white;
	color: darkgray;
}

.pink-row {
	background-color: var(--light-pink);
}

.hot-pink {
	color: var(--hot-pink);
}

#attach-form-button {
	background-color: white;
	color: darkgray;
}

#raise-jobs-button {
	background-color: white;
	color: darkgray;
}


#document-upload-button {
	background-color: var(--light-blue);
	margin-bottom: 15px;
	width: 100%;
	color: white;
}

#stock-upload-button {
	background-color: var(--light-amber);
	margin-bottom: 15px;
	width: 100%;
	color: white;
}

#offline-documents-container {
	display: flex;
	justify-content: center;
}

.outer-card {
	display: flex;
	justify-content: center;
	color: var(--hot-pink);
	font-size: 17px;
}

.stock-list {
	width: 100% !important;
}

.note-cell-1-header {
	word-break: break-all;
	width: 25%;
	padding: 6.5px !important;
	font-size: 12px !important;
	color: rgba(0, 0, 0, 0.54) !important;
}

.note-cell-2-header {
	word-break: break-all;
	width: 50%;
	padding: 6.5px !important;
	font-size: 12px !important;
	color: rgba(0, 0, 0, 0.54) !important;
}

.note-cell-3-header {
	word-break: break-all;
	width: 25%;
	padding: 6.5px !important;
	font-size: 12px !important;
	color: rgba(0, 0, 0, 0.54) !important;
}

.note-cell-1 {
	word-break: break-all;
	width: 25%;
	padding: 6.5px !important;
	font-size: 11px !important;
}

.note-cell-2 {
	word-break: break-all;
	word-break: break-word;
	width: 50%;
	padding: 6.5px !important;
	font-size: 12px !important;
}

.note-cell-3 {
	word-break: break-all;
	width: 25%;
	padding: 6.5px !important;
	font-size: 11px !important;
}

.photo-card {
	display: flex;
	justify-content: center;
	color: var(--hot-pink);
	font-size: 20px;
	height: 45px;
	margin-bottom: 15px !important;
}

.main .main {
	margin-bottom: 0px !important;
}

.form-cell-name-header {
	width: 40%;
	color: rgba(0, 0, 0, 0.54) !important;
	font-weight: 500;
	padding: 6.5px !important;
	font-size: 12px !important;
}

.form-cell-task-header {
	width: 25%;
	color: rgba(0, 0, 0, 0.54) !important;
	font-weight: 500;
	padding: 6.5px !important;
	font-size: 12px !important;
}

.form-cell-button-header {
	width: 10%;
	color: rgba(0, 0, 0, 0.54) !important;
	font-weight: 500;
	padding: 6.5px !important;
	font-size: 12px !important;
}

.form-cell-name {
	width: 40%;
	color: black !important;
	font-weight: 500;
	padding: 6.5px !important;
	font-size: 11px !important;
}

.form-cell-task {
	width: 25%;
	color: black !important;
	font-weight: 500;
	padding: 6.5px !important;
	font-size: 11px !important;
}

.form-cell-button {
	width: 10%;
	padding: 6.5px !important;
}

.form-Template-cell {
	padding: 6.5px !important;
}
.card-shadow-alt {
	box-shadow: 0 3px 6px #dedede, 0 3px 6px #dedede;
	border: solid;
	border-color: #d0d0d0;
	border-width: 1px;
	border-radius: 20px;
	padding: 22px;
}

.field {
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
}

.field-label-alt {
	color: var(--dark-blue);
	font-family: 'Roboto';
}

.field-content-alt {
	margin-bottom: 7.5px;
	color: #424242;
	max-width: 100%;
	font-family: 'Roboto';
}

.field-content-link {
	margin-bottom: 7.5px;
	color: #424242;
	max-width: 100%;
}

.full-line {
	grid-column: 1 / 3;
}

.top-card-alt {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 25px;
	margin-bottom: 15px;
}

.main-card-left {
	border-left: solid;
    border-left-width: 0.1px;
    border-left-style: ridge;
    padding-left: inherit;
}

.action-buttons-alt {
	display: flex;
	flex-direction: column;
	padding: 0;
}

.permit-to-work-button {
	background-color: #D5F4D4 !important;
    color: #00B297 !important;
	margin-bottom: 15px !important;
	width: 100%;
	box-shadow: none !important;
	border-radius: 20px !important;
}


.accept-button {
	background-color: #E5E7FF !important;
    color: #373F91 !important;
	margin-bottom: 15px !important;
	width: 100%;
	box-shadow: none !important;
	border-radius: 20px !important;
}

.start-travel-button {
	background-color: #C7DFFF !important;
    color: #3269B1 !important;
	margin-bottom: 15px !important;
	width: 100%;
	box-shadow: none !important;
	border-radius: 20px !important;
}

.risk-assessment-button {
	background-color: #AEDED2 !important;
    color: #19A580 !important;
	margin-bottom: 15px !important;
	width: 100%;
	box-shadow: none !important;
	border-radius: 20px !important;
}

.arrive-button {
	background-color: #D0EEFF !important;
    color: #00456B !important;
	margin-bottom: 15px !important;
	width: 100%;
	box-shadow: none !important;
	border-radius: 20px !important;
}

.leave-site-button {
	background-color: #FFE3C4 !important;
	color: #EAA04D !important;
	margin-bottom: 15px !important;
	width: 100%;
	box-shadow: none !important;
	border-radius: 20px !important;
}

.alt-card-title {
	padding: 10px;
	color: inherit;
	background-color: inherit;
	display: flex;
	flex-direction: column;
}

.card-header-icon-n-title{
	font-weight: 500;
	display: flex;
	align-items: center;
	padding-right: 0 !important;
}

.border-radius {
	border-radius: 20px;
	padding: 16px !important;
}

.padding {
	padding: 12.5px;
	margin-bottom: 15px;
}

.outer-card {
	display: flex;
	justify-content: center;
	color: var(--hot-pink);
	font-size: 17px;
}


.grid-photo {
	object-fit: fill;
	max-width: 100%;
	max-height: 80px;
	border: 1px solid var(--light-grey);
	border-radius: 5px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	min-height: 80px;
}

.photo-modal {
	object-fit: fill;
	max-width: 100%;
	max-height: 100%;
}

.photos-container {
	padding: 10px;
}

.note-cell-1 {
	word-break: break-all;
	width: 25%;
	padding: 6.5px !important;
	font-size: 11px !important;
}

.note-cell-2 {
	word-break: break-all;
	word-break: break-word;
	width: 50%;
	padding: 6.5px !important;
	font-size: 12px !important;
}

.note-cell-3 {
	word-break: break-all;
	width: 25%;
	padding: 6.5px !important;
	font-size: 11px !important;
}

.pink-row {
	background-color: var(--light-pink);
}

.no-elements-card-text {
	display: flex;
	justify-content: center;
	color: #738678;
    font-size: 15px;
}

.loading-elements-card-container {
	text-align: center;
}

.sub-title-in-card {
	margin-top: 20px;
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: 500;
}

.qr-code-button {
	background-color: #E5E7FF !important;
	color: #373F91 !important;
	margin-bottom: 15px !important;
	border-radius: 20px !important;
	box-shadow: none !important;
}

.photo-icon-holder {
	font-size: 20px;
	margin-right: 5px;
}

.qr-code-modal-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.information-card-title {
	font-weight: 500;
	font-size: 22px;
	margin-bottom: 8px;
}

.icon-button-holder {
	margin-left: 4px;
	padding: 0;
	vertical-align: text-bottom;
}

.pin-icon-holder {
	color: #e91e63;
	font-size: 22px;
}

.almost-bold-text {
	font-weight: 500;
}

.break-word-wrap {
	word-wrap: break-word;
}

.touch-app-icon-color {
	color: #A252D4;
}
#outer-card {
	padding: 10px;
	padding-top: 5px;
	max-width: 95%;
	margin: 12.5px auto;
	min-width: 280px;
}

#outer-card-button {
	padding: 10px;
	max-width: 95%;
	margin: auto 12.5px;
	min-width: 280px;
}

#loadMoreBtn {
	margin-top: 10px;
	border-radius: 20px;    
	color: #3269B1 !important;
	border-color: #3269B1 !important;
}

.card-shadow {
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

p {
	margin: 0;
	font-size: 20px;
}

.top-row {
	display: flex;
	margin-bottom: 5px;
	justify-content: space-between;
}

.status-container {
	display: flex;
}

.task-number {
	padding: 5.5px 7px;
	border-radius: 5px;
	font-size: 14.5px;
	font-weight: 500;
}

.due-today-flag {
	padding: 5.5px 7px;
	border-radius: 5px;
	font-size: 14.5px;
	background-color: var(--light-red);
	color: white;
	margin-right: 5px;
	font-weight: 550;
}

.status-container {
	display: flex;
	flex-direction: row;
}

.header {
	font-size: 16px;
	font-weight: 500;
}

.content {
	font-size: 14px;
	/* font-style: italic; */
	margin-left: 5px;
	line-height: 15px;
}

.content-container {
	display: grid;
	margin-bottom: 10px;
	grid-template-columns: 30% 70%;
}

.cell1 {
	word-break: break-all;
	width: 25%;
	padding: 6.5px !important;
	font-size: 11px !important;
}

.cell2 {
	word-break: break-all;
	width: 50%;
	padding: 6.5px !important;
}

.cell3 {
	word-break: break-all;
	width: 40%;
	padding: 6.5px !important;
	font-size: 12px !important;
}

.cell4 {
	word-break: break-all;
	width: 20%;
	padding: 6.5px !important;
	font-size: 12px !important;
}

.cell5 {
	word-break: break-all;
	width: 10%;
	padding: 6.5px !important;
	font-size: 12px !important;
}

.cell {
	word-break: break-all;
	/* padding: 6.5px !important;
	font-size: 12px !important; */
}

.row-cell {
	display: flex !important;
}
.main {
	max-width: 650px;
	width: 100%;
	margin-bottom: 140px;
}

.main-timesheet {
	max-width: 650px;
	width: 100%;
	padding-left: 5px;
	padding-right: 5px;
}

.action-buttons-list {
	display: flex;
	justify-content: space-between !important;
	flex-direction: row;
}

.search-popup {
	width: 100% !important;
	margin: 10px !important;
	height: 330px;
	min-height: 330px;
}

#filters-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.reactive-filter {
	display: flex;
	align-items: flex-end;
	flex-direction: column;
}

.open-filter {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}

.dialog-content-root {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.no-jobtasks-card {
	max-width: 90%;
	margin: auto;
	text-align: center;
	margin-top: 25px;
}

.no-jobtasks-card > h1 {
	font-size: 22px;
	font-style: italic;
	line-height: 30px;
}

#clear-search-fab {
	background-color: var(--light-red);
	position: fixed;
	right: 15px;
	bottom: 65px;
}

.hidden {
	display: none !important;
}

.not-hidden {
	display: block !important;
}

#search-fab {
	position: fixed;
	right: 15px;
	bottom: 15px;
	background-color: var(--light-blue);
}

#status-fab {
	position: fixed;
	left: 15px;
	bottom: 18px;
	background-color: var(--dark-blue);
}

#status-fab p {
	font-size: 14px;
	color: var(--off-white);
}

#is-due-today-row {
	display: flex;
	align-items: center;
	margin-bottom: -10px !important;
}

#is-not-synced-row {
	display: flex;
	align-items: center;
	margin-bottom: -10px !important;
	margin-top: -10px !important;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    width: 350px;
    padding: 25px;
}
.text-inputs {
    padding: 10px;
    margin-bottom: 10px;
}

.login-buttons {
    margin-top: 35px !important;
}

.outer-container {
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

#logo {
    align-self: center;
}

body {
    overscroll-behavior: contain;
}

.title-text {
    font-size: 24px;
    margin: auto;
    padding-top: 5px;
    color: #526370;
}

#loginBtn {
    background-color: #526370;
    color: white;
}

.btn-container {
	display: flex;
	align-items: center;
	margin: 25px 0px;
	justify-content: space-between;
}

.self-delivery-label {
	font-size: 16px !important;
	color: var(--hot-pink) !important;
	font-weight: 500 !important;
	margin-left: 5px !important;
}

#expected-date {
	border: 0px !important;
	padding: 0px !important;
	margin-top: 30px !important;
	width: 100%;
	background-color: transparent;
}

.timesheet-cell {
    padding-left: 0px !important;
    padding-right: 7.5px !important;
}

td:last-child,
th:last-child {
    padding-right: 7.5px !important;
}

.time-string {
    font-size: 12px;
    font-weight: bold;
}

.date-string {
    font-size: 10px;
    font-style: italic;
}

/* .task-number {
    font-size: 12px;
} */

.time-sheet-details tbody tr:nth-child(odd) {
    background-color: #f2f2f2;
}

.header-text {
    color: var(--dark-blue);
}

.edited-row {
    background-color: var(--light-pink) !important;
}

#details-outer,
#action-buttons {
	padding: 5px;
	max-width: 95%;
	margin: 5px auto;
	min-width: 280px;
}

#document-upload-button {
	background-color: var(--light-blue);
	margin-bottom: 15px;
	width: 100%;
	color: white;
}

#submit-expense-button {
	background-color: #5cb85c;
	margin-bottom: 15px;
	width: 100%;
	color: white;
}

#edit-expense-button {
	background-color: #ffc107;
	margin-bottom: 15px;
	width: 100%;
	color: white;
}

.number-question-container,
.text-question-container {
	display: flex;
	flex-direction: column;
}

.boolean-question-container {
	display: flex;
	flex-direction: row;
}

.boolean-answer-box {
	height: 32.5px;
	width: 75px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid var(--light-grey);
	cursor: pointer;
	border-radius: 2px;
	margin-right: 2.5px;
}

.green {
	background-color: var(--light-green);
	color: white;
	font-weight: 500;
}

.red {
	background-color: var(--light-red);
	color: white;
	font-weight: 500;
}

.grey {
	background-color: var(--light-grey);
	color: white;
	font-weight: 500;
}

.section-question-container {
	margin-top: 7.5px;
	border-top: 1.25px var(--light-grey) solid;
}
.base-question {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	padding: 15px 0;
}

.base-question:not(:last-child) {
	border-bottom: 1.25px var(--light-grey) solid;
}

.green-border {
	border: 2px solid var(--light-green) !important;
}

.orange-border {
	border: 2px solid var(--dark-amber) !important;
}

/* Number Question */

.number-question-container-input {
	width: 100%;
	height: 100%;
	padding: 5px;
	border-radius: 5px;
	border: none;
	border: solid 1.5px #ccc;
}

.number-question-container-input:focus {
	border: solid 2.2px #3f51b5;
	border-style: solid;
	outline-color: white;
	box-shadow: 0px 0px 4px 1px #495ab575;
}

/* Input Questions */

.file-input-container > input[type='text'],
.file-input-container > input[type='number'] {
	height: 37.5px;
}

.input-error {
	border: solid 2.2px var(--light-red);
	border-style: solid;
	outline-color: white;
	box-shadow: 0px 0px 4px 1px var(--light-red);
}

.input-error-text {
	font-size: 10px;
	font-style: italic;
	margin-top: 5px;
	color: var(--light-red);
}

.text-question-container {
	width: 100%;
}

.date-question {
	width: 100% !important;
}

.date-question > input {
	padding: 5px !important;
}

.upload-Btn {
	background-color: var(--light-grey) !important;
	color: white !important;
	margin-right: 2.5px !important;
}

.question-container {
	margin-bottom: 7.5px;
	font-weight: 500;
	color: var(--dark-grey);
	font-size: 1.06rem;
}

.alternative-buttons-container {
	margin-bottom: 7.5px;
}

.file-name-container {
	word-break: break-all;
}

.file-name {
	color: var(--light-grey);
	font-weight: 300;
}

.button-icon {
	margin-right: 5px;
}

.answer-container {
	width: 100%;
}

.image-container {
	border: solid 1px var(--light-grey);
	width: 100%;
	min-height: 150px;
	margin-bottom: 10px;
}

.image-container > img {
	width: 100%;
}

.width-100 {
	width: 100% !important;
}

.select-root {
	border: solid 1.5px #ccc;
	border-radius: 5px;
	font-size: 20px;
	height: 40px;
	background-color: white;
}

.select-root > option {
	color: black;
}

.number-question-container input,
.text-question-container input,
.date-question-container input,
.answer-container input {
	font-size: 20px;
	min-height: 40px !important;
}

.answer-container input {
	border: #ccc solid 1px;
	padding: 5px;
}

input[type='datetime-local'],
input[type='date'] {
	font-size: 18px;
	font-family: Roboto;
	border-radius: 5px;
	border: solid 1.5px #ccc;
	padding: 4px;
	background-color: white;
}

.preview-photo {
	width: 250px;
	margin-top: 10px;
}

.spacer {
	margin-top: 10px;
}

.raise-job-button {
	display: 'inline-flex';
	background-color: var(--light-blue) !important;
	color: white !important;
	margin-top: 10px !important;
}

.add-asset-button {
	display: 'inline-flex';
	color:#e60000 !important;
	font-size: 14px;
}

.job-number {
	margin-top: 10px;
	margin-left: 1px;
	font-size: 16px;
	font-weight: 500;
	font-style: italic;
}

#dialogLoading .MuiDialog-paper {
    margin: 48px;
    position: relative;
    background-color: transparent;
    height: auto;
    box-shadow: 0 0 0 0 !important;
    align-items: center;
}
#dialogLoading h1 {
    color: #FFF;
}
#dialogLoading .MuiDialogProgress-svg {
    color: #FFF;
}
.main-form-library {
	max-width: 650px;
	width: 100%;
	margin-bottom: 140px;
	padding-left: 7.5px;
	padding-right: 7.5px;
}

.no-templates-card {
	max-width: 90%;
	margin: auto;
	text-align: center;
	margin-top: 25px;
	font-size: 22px;
	font-style: italic;
	line-height: 30px;
}

.form-card {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 20px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.top-row-form-card {
	display: grid;
	grid-template-columns: 100%;
	grid-column-gap: 7.5px;
	padding: 7.5px;
}

.bottom-row-form-card {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	padding: 7.5px;
	margin-bottom: 5px;
}

.label-form {
	font-size: 16px;
	color: var(--dark-blue);
	font-weight: 500;
}

.header-container-form {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.content-form {
	font-size: 16px;
	margin-bottom: 5px;
	margin-top: auto;
}

.form-start-edit-button {
	border: 0 !important;
	border-radius: 0 !important;
	height: 35px;
	outline: none;
	/* text-transform: uppercase; */
}

.library-p-tag {
	margin-left: 5px !important;
	font-size: 20px;
	margin: auto;
	display: inline;
	font-size: 500;
}

#create-form-date-input {
	border: 0px !important;
	background-color: transparent !important;
}

.main-asset {
	max-width: 650px;
	width: 100%;
	margin-bottom: 140px;
	padding-left: 7.5px;
	padding-right: 7.5px;
}

.search-popup-asset {
	width: 100%;
	margin: 10px !important;
	height: 220px;
	min-height: 160px;
}

.asset-card {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.top-row-asset {
	display: grid;
	grid-template-columns: 1fr;
	grid-column-gap: 7.5px;
	padding: 7.5px;
}

.top-row-asset {
	display: grid;
	grid-template-columns: 50% 25% 25%;
	grid-column-gap: 7.5px;
	padding: 7.5px;
}

.top-content-asset {
	font-size: 18px;
	color: #000000;
	margin-bottom: 5px;
}

.bottom-row-asset {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	padding: 7.5px;
}

.label-asset {
	font-size: 16px;
	color: var(--dark-blue);
	font-weight: 500;
}

.header-container-asset {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.content-asset {
	font-size: 16px;
	margin-bottom: 5px;
	margin-top: auto;
}

#assetpagdiv {
	margin-top: 20px;
}

.top-row-choose-asset {
	display: grid;
	grid-template-columns: 50% 30% 20%;
	grid-column-gap: 5.5px;
	padding: 5.5px;
}

.MuiIconButton-root {
	padding:0 !important;
}

.MuiPaper-root {
	overflow-y: visible !important;
}

.MuiCardHeader-title{
	font-size: 20px !important;
}

.main-site {
	max-width: 650px;
	width: 100%;
	margin-bottom: 140px;
	padding-left: 7.5px;
	padding-right: 7.5px;
}

.search-popup-site {
	width: 100%;
	margin: 10px !important;
	height: 160px;
	min-height: 160px;
}

.card-shadow-site {
	box-shadow: 0 3px 6px rgba(34, 109, 248, 0.288), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.site-card {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.top-row-site {
	display: grid;
	grid-template-columns: 1fr;
	grid-column-gap: 7.5px;
	padding: 7.5px;
}

.top-row-choose-site {
	display: grid;
	grid-template-columns: 60% 40%;
	grid-column-gap: 7.5px;
	padding: 7.5px;
}

.top-row-choose-site2 {
	display: grid;
	grid-template-columns: 50% 40% 10%;
	grid-column-gap: 7.5px;
	padding: 7.5px;
}

.top-content-site {
	font-size: 14px;
	color: #000000;
	margin-bottom: 5px;
}

.bottom-row-site {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	padding: 7.5px;
}

.label-site {
	font-size: 16px;
	color: rgb(238, 8, 8);
	font-weight: 500;
}

.header-container-site {
	display: flex;
	flex-direction: column;
	/*justify-content: space-between;*/
}

.content-site {
	font-size: 14px;
	margin-bottom: 5px;
	margin-top: auto;
}

#sitepagdiv {
	margin-top: 20px;
}

.MuiIconButton-root {
	padding: 0 !important;
}

.storage-area-details-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.storage-area-card {
	border: 2.5px solid var(--light-grey);
	margin-bottom: 10px;
	padding: 10px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	display: flex;
	flex-direction: column;
	width: 100%;
}

.storage-area-details {
	width: 100%;
	padding-top: 20px;
	padding-left: 24px;
	padding-right: 24px;
}

.label-storage-area {
	font-size: 16px;
	color: rgb(238, 8, 8);
	font-weight: 500;
}
.content-storage-area {
	font-size: 14px;
	margin-bottom: 5px;
	margin-top: auto;
}

.storage-area-details .storage-area-card {
	padding: 6px;
	margin-bottom: 15px;
	border: 1px solid grey;
}

.storage-area-details .label-storage-area {
	font-size: 16px;
	font-weight: 1000;
	color: var(--dark-blue);
	font-family: 'Montserrat', sans-serif;
}

.storage-area-details .content-storage-area {
	font-size: 18px;
	margin-bottom: 7.5px;
	color: #424242;
	max-width: 100%;
}

.storage-area-details .first {
	color: black;
	font-size: 30px !important;
	font-weight: 700;
	font-family: 'Montserrat', sans-serif;
}

.no-stocks-text {
	font-size: 20px;
	font-style: italic;
	line-height: 30px;
	color: var(--hot-pink);
}

.clear-button-container {
	flex: 0 1;
	margin-top: 28px;
	justify-self: flex-end;
}

.clear-button {
	cursor: pointer;
}

.dialog-action-buttons {
	display: flex;
	justify-content: space-between !important;
	flex-direction: row;
}

.select-container {
	display: flex;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	width: 100%;
	margin: 10px 20px;
}

.select-sub-container {
	flex: 1 1;
}

.storage-areas-container {
	margin-top: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.storage-areas-list {
	max-width: 650px;
	width: 100%;
	margin-top: 5px;
	margin-bottom: 140px;
	padding-left: 7.5px;
	padding-right: 7.5px;
}

.no-storage-areas-text {
	font-size: 22px;
	font-style: italic;
	line-height: 30px;
	color: var(--hot-pink);
}

.table-left-padding {
	padding-left: 0px !important;
}

.table-head-cell {
	font-size: 16px;
	font-weight: 1000;
	color: var(--dark-blue);
	font-family: 'Montserrat', sans-serif;
}

.table-body-cell {
	font-size: 18px;
	color: #424242;
}

/* CSS reset with box-sizing fix */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

html {
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	line-height: 1;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
	font-family: 'Roboto';
}

a {
	color: inherit;
	text-decoration: none;
}

:root {
	--light-grey: #9e9e9e;
	--dark-grey: #424242;
	--dark-blue: #3f51b5;
	--light-blue: #2196f3;
	--dark-green: #2e7d32;
	--light-green: #66bb6a;
	--light-amber: #ffc107;
	--dark-amber: #ff6f00;
	--light-yellow: #fdd835;
	--dark-yellow: #f57f17;
	--light-red: #f44336;
	--dark-red: #c62828;
	--off-white: #f1f2f7;
	--light-pink: #f8bbd0;
	--hot-pink: #e91e63;
}

