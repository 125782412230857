.select-asset-button {
    background-color: #E5E7FF !important;
    color: #373F91 !important;
    margin-bottom: 25px !important;
    width: 100% !important;
    box-shadow: none !important;
    border-radius: 20px !important;
}

.old-select-asset-button {
    background-color: #b821f3 !important;
	color: white !important;
	width: 100% !important;
	margin-right: 5px !important;
	margin-bottom: 15px !important;
}

.scan-asset-button {
    background-color: #ffe2c0 !important;
    color: #f69c35 !important;
    margin-bottom: 25px !important;
    width: 100% !important;
    box-shadow: none !important;
    border-radius: 20px !important;
}

.old-scan-asset-button {
    background-color: #f59e38 !important;
	color: white !important;
	width: 100% !important;
	margin-left: 5px !important;
	margin-bottom: 15px !important;
}

.buttons-grid {
    text-align: center;
    display: flex;
}

.label-margin {
    margin-top: 2px !important;
}

.icon {
    font-size: 18px !important;
}