.document-tag-list {
  display: flex !important;
  flex-wrap: wrap;
}

.document-tag {
  margin: 5px;
  margin-right: 0;
	font-weight: 550;
}

.document-tag-placeholder.MuiChip-outlined {
	border-style: dashed !important;
	color: lightgray !important;
}

.document-tag-hover:hover {
	cursor: pointer;
}

.document-tag-chip-disabled {
	cursor: not-allowed !important;
}

.color-black {
	color: black;
}

.left-margin {
	margin-left: 12px;
}

.icon-holder {
	position: absolute;
	cursor: pointer;
	top: 5px;
	right: 5px;
}

.no-display {
	display: none;
}