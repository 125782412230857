.btn-container {
	display: flex;
	align-items: center;
	margin: 25px 0px;
	justify-content: space-between;
}

.self-delivery-label {
	font-size: 16px !important;
	color: var(--hot-pink) !important;
	font-weight: 500 !important;
	margin-left: 5px !important;
}

#expected-date {
	border: 0px !important;
	padding: 0px !important;
	margin-top: 30px !important;
	width: 100%;
	background-color: transparent;
}
