.number-question-container,
.text-question-container {
	display: flex;
	flex-direction: column;
}

.boolean-question-container {
	display: flex;
	flex-direction: row;
}

.boolean-answer-box {
	height: 32.5px;
	width: 75px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid var(--light-grey);
	cursor: pointer;
	border-radius: 2px;
	margin-right: 2.5px;
}

.green {
	background-color: var(--light-green);
	color: white;
	font-weight: 500;
}

.red {
	background-color: var(--light-red);
	color: white;
	font-weight: 500;
}

.grey {
	background-color: var(--light-grey);
	color: white;
	font-weight: 500;
}

.section-question-container {
	margin-top: 7.5px;
	border-top: 1.25px var(--light-grey) solid;
}
.base-question {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	padding: 15px 0;
}

.base-question:not(:last-child) {
	border-bottom: 1.25px var(--light-grey) solid;
}

.green-border {
	border: 2px solid var(--light-green) !important;
}

.orange-border {
	border: 2px solid var(--dark-amber) !important;
}

/* Number Question */

.number-question-container-input {
	width: 100%;
	height: 100%;
	padding: 5px;
	border-radius: 5px;
	border: none;
	border: solid 1.5px #ccc;
}

.number-question-container-input:focus {
	border: solid 2.2px #3f51b5;
	border-style: solid;
	outline-color: white;
	box-shadow: 0px 0px 4px 1px #495ab575;
}

/* Input Questions */

.file-input-container > input[type='text'],
.file-input-container > input[type='number'] {
	height: 37.5px;
}

.input-error {
	border: solid 2.2px var(--light-red);
	border-style: solid;
	outline-color: white;
	box-shadow: 0px 0px 4px 1px var(--light-red);
}

.input-error-text {
	font-size: 10px;
	font-style: italic;
	margin-top: 5px;
	color: var(--light-red);
}

.text-question-container {
	width: 100%;
}

.date-question {
	width: 100% !important;
}

.date-question > input {
	padding: 5px !important;
}

.upload-Btn {
	background-color: var(--light-grey) !important;
	color: white !important;
	margin-right: 2.5px !important;
}

.question-container {
	margin-bottom: 7.5px;
	font-weight: 500;
	color: var(--dark-grey);
	font-size: 1.06rem;
}

.alternative-buttons-container {
	margin-bottom: 7.5px;
}

.file-name-container {
	word-break: break-all;
}

.file-name {
	color: var(--light-grey);
	font-weight: 300;
}

.button-icon {
	margin-right: 5px;
}

.answer-container {
	width: 100%;
}

.image-container {
	border: solid 1px var(--light-grey);
	width: 100%;
	min-height: 150px;
	margin-bottom: 10px;
}

.image-container > img {
	width: 100%;
}

.width-100 {
	width: 100% !important;
}

.select-root {
	border: solid 1.5px #ccc;
	border-radius: 5px;
	font-size: 20px;
	height: 40px;
	background-color: white;
}

.select-root > option {
	color: black;
}

.number-question-container input,
.text-question-container input,
.date-question-container input,
.answer-container input {
	font-size: 20px;
	min-height: 40px !important;
}

.answer-container input {
	border: #ccc solid 1px;
	padding: 5px;
}

input[type='datetime-local'],
input[type='date'] {
	font-size: 18px;
	font-family: Roboto;
	border-radius: 5px;
	border: solid 1.5px #ccc;
	padding: 4px;
	background-color: white;
}

.preview-photo {
	width: 250px;
	margin-top: 10px;
}

.spacer {
	margin-top: 10px;
}

.raise-job-button {
	display: 'inline-flex';
	background-color: var(--light-blue) !important;
	color: white !important;
	margin-top: 10px !important;
}

.add-asset-button {
	display: 'inline-flex';
	color:#e60000 !important;
	font-size: 14px;
}

.job-number {
	margin-top: 10px;
	margin-left: 1px;
	font-size: 16px;
	font-weight: 500;
	font-style: italic;
}

#dialogLoading .MuiDialog-paper {
    margin: 48px;
    position: relative;
    background-color: transparent;
    height: auto;
    box-shadow: 0 0 0 0 !important;
    align-items: center;
}
#dialogLoading h1 {
    color: #FFF;
}
#dialogLoading .MuiDialogProgress-svg {
    color: #FFF;
}