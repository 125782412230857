.details-list {
    position: absolute !important;
    bottom: 0;
    padding-top: 0 !important;
    padding-bottom: 0.3em !important;
    width: 100%;
}

.details-list .version-information {
    padding-bottom: 0.2em;
}

.details-list .version-information > .MuiListItemText-root {
    margin-bottom: 0;
}

.details-list .version-information > .MuiListItemText-root > .MuiListItemText-primary {
    font-size: 0.6rem;
}

.details-list .check-for-updates {
    right: 0.8em;
}

.details-list .user-information > .MuiListItemText-root > .MuiListItemText-primary {
    font-size: 0.8rem;
}

.drawer-div-container {
    margin-bottom: 60px;
}

.appbar {
    box-shadow: none !important;
    background-color: #373f91 !important;
}

.appbar-title {
    margin-left: 15px;
    flex-grow: 1;
    font-weight: 500;
    font-family: Roboto;
}

.menu-icon {
    font-size: 30px !important; 
    font-weight: lighter !important;
}