.main {
	max-width: 650px;
	width: 100%;
	margin-bottom: 140px;
}

.main-timesheet {
	max-width: 650px;
	width: 100%;
	padding-left: 5px;
	padding-right: 5px;
}

.action-buttons-list {
	display: flex;
	justify-content: space-between !important;
	flex-direction: row;
}

.search-popup {
	width: 100% !important;
	margin: 10px !important;
	height: 330px;
	min-height: 330px;
}

#filters-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.reactive-filter {
	display: flex;
	align-items: flex-end;
	flex-direction: column;
}

.open-filter {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}

.dialog-content-root {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.no-jobtasks-card {
	max-width: 90%;
	margin: auto;
	text-align: center;
	margin-top: 25px;
}

.no-jobtasks-card > h1 {
	font-size: 22px;
	font-style: italic;
	line-height: 30px;
}

#clear-search-fab {
	background-color: var(--light-red);
	position: fixed;
	right: 15px;
	bottom: 65px;
}

.hidden {
	display: none !important;
}

.not-hidden {
	display: block !important;
}

#search-fab {
	position: fixed;
	right: 15px;
	bottom: 15px;
	background-color: var(--light-blue);
}

#status-fab {
	position: fixed;
	left: 15px;
	bottom: 18px;
	background-color: var(--dark-blue);
}

#status-fab p {
	font-size: 14px;
	color: var(--off-white);
}

#is-due-today-row {
	display: flex;
	align-items: center;
	margin-bottom: -10px !important;
}

#is-not-synced-row {
	display: flex;
	align-items: center;
	margin-bottom: -10px !important;
	margin-top: -10px !important;
}
