.cursor-pointer {
    cursor: pointer;
}

.label-margin {
    margin-top: 2px !important;
}

.icon {
    font-size: 20px !important;
}

.action-button {
    background-color: #E5E7FF !important;
    color: #373F91 !important;
    margin-bottom: 15px !important;
    border-radius: 20px !important;
    box-shadow: none !important;
    width: 100%;
    margin-top: 10px !important;
}

.expansion-icon-holder {
    display: flex;
    align-items: center;
    padding-left: 0 !important;
}

.card-title-max-width {
    max-width: 150px;
}