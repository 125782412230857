.storage-areas-container {
	margin-top: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.storage-areas-list {
	max-width: 650px;
	width: 100%;
	margin-top: 5px;
	margin-bottom: 140px;
	padding-left: 7.5px;
	padding-right: 7.5px;
}

.no-storage-areas-text {
	font-size: 22px;
	font-style: italic;
	line-height: 30px;
	color: var(--hot-pink);
}
